import Carousel from "../carousel/Carousel";
import "./Projectsection.scss";

const Projectsection = ({ projects }) => {
  return (
    <>
      <div className="projects-section" id="projects">
        <div className="projects-wrapper">
          <h1 className="projects-heading">Unsere Projekte</h1>
          <div className="projects-container">
            <Carousel items={projects} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Projectsection;
