import React from "react";
import "./Button.scss";

const Button = ({ children, type, size, inverted, onClick, disabled }) => {
  return (
    <button
      className={`btn ${type ? type : ""} ${size ? size : ""} ${
        inverted ? "inverted" : ""
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
