import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import {
  HomePage,
  ServicesPage,
  AboutPage,
  ContactPage,
  ImprintPage,
  PrivacyPage,
  AccessibilityPage,
} from "./pages";

import "./App.scss";
import NotfoundPage from "./pages/404";

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="*" element={<NotfoundPage />} />
        <Route path="/" exact element={<HomePage />} />
        <Route path="/services" exact element={<ServicesPage />} />
        <Route path="/about" exact element={<AboutPage />} />
        <Route path="/contact" exact element={<ContactPage />} />
        <Route path="/imprint" exact element={<ImprintPage />} />
        <Route path="/privacy" exact element={<PrivacyPage />} />
        <Route path="/accessibility" exact element={<AccessibilityPage />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
