import { useRef } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { MdOutlineOpenInNew } from "react-icons/md";
import Card from "../card/Card";
import "./Carousel.scss";

const Carousel = ({ items }) => {
  const carouselRef = useRef();

  const scrollToRight = (e) => {
    let width = document.getElementsByClassName("carousel-item")[0].offsetWidth;
    scroll(width);
  };

  const scrollToLeft = (e) => {
    let width = document.getElementsByClassName("carousel-item")[0].offsetWidth;
    scroll(-width);
  };

  const scroll = (value) => {
    carouselRef.current.scrollBy({
      left: value,
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div id="carousel-wrapper">
      <FaAngleLeft onClick={scrollToLeft} />
      <div className="carousel" ref={carouselRef}>
        {items.map((item, index) => {
          return (
            <div className="carousel-item" key={index}>
              <Card>
                <Card.Topline>{item.type}</Card.Topline>
                <Card.Image src={item.image} />
                <Card.Title>
                  <a href={item.link} target="_blank" rel="noreferrer">
                    {item.name}
                  </a>
                  <img src="images/openin.svg" />
                </Card.Title>
                <Card.Body>{item.body}</Card.Body>
              </Card>
            </div>
          );
        })}
      </div>
      <FaAngleRight onClick={scrollToRight} />
    </div>
  );
};

export default Carousel;
