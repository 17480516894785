import React from "react";
import Button from "../button/Button";
import { HashLink as Link } from "react-router-hash-link";
import { IoIosApps, IoIosPhonePortrait, IoIosTv } from "react-icons/io";
import "./Servicesection.scss";

const ServiceSection = ({ services }) => {
  return (
    <div>
      <div className="service-section">
        <div className="service-wrapper">
          <h1 className="heading dark center">Unsere Dienstleistungen</h1>
          <div className="service-container">
            <Link smooth to="/services#web" className="service-container-card">
              <div className="service-container-card-info">
                <div className="icon">
                  <IoIosTv />
                </div>
                <h4>Web</h4>
                <p>Anwendungen</p>
                <ul className="service-container-features">
                  <li>Webseiten</li>
                  <li>Web Shops</li>
                  <li>ReactJS, WordPress</li>
                </ul>
                <Button size="wide" inverted="inverted">
                  Mehr erfahren
                </Button>
              </div>
            </Link>
            <Link
              smooth
              to="/services#mobile"
              className="service-container-card"
            >
              <div className="service-container-card-info">
                <div className="icon">
                  <IoIosPhonePortrait />
                </div>
                <h4>Mobile</h4>
                <p>Apps</p>
                <ul className="service-container-features">
                  <li>Cross-Plattform</li>
                  <li>iOS & Android</li>
                  <li>Modern Design</li>
                </ul>
                <Button size="wide" inverted="inverted">
                  Mehr erfahren
                </Button>
              </div>
            </Link>
            <Link
              smooth
              to="/services#individual"
              className="service-container-card"
            >
              <div className="service-container-card-info">
                <div className="icon">
                  <IoIosApps />
                </div>
                <h4>IT-Systeme</h4>
                <p>Software</p>
                <ul className="service-container-features">
                  <li>Client-Server Anwendungen</li>
                  <li>Datenbanksysteme</li>
                  <li>IT-Beratung</li>
                </ul>
                <Button size="wide" inverted="inverted">
                  Mehr erfahren
                </Button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSection;
