import React, { useEffect } from "react";
import Button from "../../components/button/Button";

const NotfoundPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  return (
    <div className="hero-section">
      <div className="container center">
        <img src="images/notfound.svg" />
        <h1 className="dark mt-3">404 - Seite nicht gefunden</h1>
        <p className="dark mb-4">
          Die von Ihnen angeforderte Seite <b>{window.location.pathname} </b>
          konnten wir in diesem Universum nicht finden.
        </p>
        <a href="/">
          <Button size="medium">Zur Startseite</Button>
        </a>
      </div>
    </div>
  );
};

export default NotfoundPage;
