import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import Button from "../button/Button";
import "./Navbar.scss";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [active, setActive] = useState("/");
  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => {
    setClick(false);
  };

  return (
    <>
      <div className="navbar">
        <div className="navbar-container container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img height={32} src="images/logo.svg" />
            lbware.
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            {click ? <FaTimes /> : <FaBars />}
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li
              className={active == "/" ? "nav-item active" : "nav-item"}
              onClick={() => setActive("/")}
            >
              <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li
              className={active == "/services" ? "nav-item active" : "nav-item"}
              onClick={() => setActive("/services")}
            >
              <Link
                to="/services"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Dienstleistungen
              </Link>
            </li>
            <li
              className={active == "/about" ? "nav-item active" : "nav-item"}
              onClick={() => setActive("/about")}
            >
              <Link to="/about" className="nav-links" onClick={closeMobileMenu}>
                Über uns
              </Link>
            </li>
            <li className="nav-btn" onClick={() => setActive("/contact")}>
              <Link
                to="/contact"
                className="btn-link"
                onClick={closeMobileMenu}
              >
                <Button type="outline" size="medium">
                  Kontakt
                </Button>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Navbar;
