import React from "react";
import { Link } from "react-router-dom";
import Button from "../button/Button";
import "./Herosection.scss";

const Herosection = ({
  light,
  topLine,
  headline,
  description,
  buttonLabel,
  buttonLink,
  enableButton,
  img,
  alt,
  imgStart,
  anchorId,
}) => {
  return (
    <>
      <div
        className={light ? "hero-section" : "hero-section darkBg"}
        id={anchorId}
      >
        <div className="container">
          <div
            className="row hero-row"
            style={{
              display: "flex",
              flexDirection: imgStart === "start" ? "row-reverse" : "row",
            }}
          >
            <div className="col">
              <div className="hero-text-wrapper">
                <div className="top-line">{topLine}</div>
                <h1 className={`heading${light ? " dark" : " light"}`}>
                  {headline}
                </h1>
                <div className={`hero-subtitle${light ? " dark" : " light"}`}>
                  {description && (
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                  )}
                </div>
                {enableButton && (
                  <Link to={buttonLink}>
                    <Button size="wide" inverted={!light}>
                      {buttonLabel}
                    </Button>
                  </Link>
                )}
              </div>
            </div>
            <div className="col">
              <div className="hero-image-wrapper">
                <img src={img} alt={alt} className="hero-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Herosection;
