import React from "react";
import { GiRoundStar } from "react-icons/gi";
import "./Card.scss";

const Image = ({ src }) => (
  <div className="cardview-image-wrapper">
    <img className="cardview-image" src={src} />
  </div>
);

const Topline = ({ children }) => (
  <p className="cardview-topline">{children}</p>
);

const Title = ({ children }) => (
  <h2 className="cardview-headline">{children}</h2>
);

const Body = ({ children }) => <p className="cardview-body">{children}</p>;

const Rating = ({ rating }) => (
  <div className="cardview-rating">
    {Array.from({ length: rating }, (v, i) => (
      <GiRoundStar key={`star-${i}`} />
    ))}
  </div>
);

const Card = ({ children }) => {
  return (
    <div className="cardview">
      <div className="cardview-container">{children}</div>
    </div>
  );
};

Card.Image = Image;
Card.Topline = Topline;
Card.Title = Title;
Card.Body = Body;
Card.Rating = Rating;

export default Card;
