import { useEffect, useState } from "react";
import Button from "../button/Button";
import Checkbox from "../checkbox/Checkbox";
import Input from "../input/Input";
import ValidatedInput from "../input/validate-input/ValidatedInput";
import Textarea from "../textarea/Textarea";
import "./Contactform.scss";

const Contactform = () => {
  const [checked, setChecked] = useState(false);
  const [data, setData] = useState({
    first: "",
    last: "",
    email: "",
    phone: "",
    message: "",
  });

  const [error, setError] = useState({
    first: false,
    last: false,
    email: false,
    phone: false,
  });

  const submit = async () => {
    if (!error.first && !error.last && !error.email && !error.phone) {
      let temp = { ...data };
      temp.first = filterBrackets(data.first);
      temp.last = filterBrackets(data.last);
      temp.message = filterBrackets(data.message);

      await fetch("https://api.alb-ware.de/index.php", {
        method: "POST",
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: `first=${temp.first}&last=${temp.last}&email=${temp.email}&phone=${temp.phone}&message=${temp.message}`,
      });

      setData({ first: "", last: "", email: "", phone: "", message: "" });
    }
  };

  const validateEmail = (email) => {
    return (
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) !== null || email.length == 0
    );
  };

  const validatePhone = (phone) => {
    return (
      phone.match(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
      ) !== null || phone.length == 0
    );
  };

  const handlePhone = (e) => {
    setError({ ...error, phone: !validatePhone(data.phone) });
  };

  const handleEmail = (e) => {
    setError({ ...error, email: !validateEmail(data.email) });
  };

  const handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
  };

  const filterBrackets = (string) => {
    return string.replaceAll("<", "").replaceAll(">", "");
  };

  return (
    <div className="contact-section">
      <h1 className="heading dark center">Kontakt</h1>
      <div className="contact-wrapper">
        <div className="row">
          <div className="col">
            <img src={"/images/contact.svg"} alt="Kontakt" />
          </div>
          <div className="col">
            <div className="name">
              <Input
                name="first"
                label="Vorname"
                autoComplete="given-name"
                placeholder="Vorname"
                required
                onChange={handleUserInput}
                value={data.first}
                error={error.first}
              />
              <Input
                name="last"
                label="Nachname"
                autoComplete="family-name"
                placeholder="Nachname"
                required
                onChange={handleUserInput}
                value={data.last}
                error={error.last}
              />
            </div>
            <ValidatedInput
              name="email"
              label="E-Mail"
              value={data.email}
              required
              onChange={handleUserInput}
              placeholder="max.mustermann@muster.de"
              onBlur={handleEmail}
              error={error.email}
            />
            <ValidatedInput
              name="phone"
              label="Telefon"
              value={data.phone}
              onChange={handleUserInput}
              placeholder="01522612371263"
              onBlur={handlePhone}
              error={error.phone}
            />
            <Textarea
              name="message"
              label="Ihre Nachricht"
              placeholder="Ihre Nachricht an uns."
              onChange={handleUserInput}
              value={data.message}
            />
            <Checkbox
              onClick={() => setChecked(!checked)}
              label={`Ich akzeptiere die in der <a href="/privacy"><u>Datenschutzerklärung</u></a> genannten Konditionen.`}
            />

            <div className="mt-3 mb-2 center">
              <Button disabled={!checked} size="medium" onClick={submit}>
                Absenden
              </Button>
            </div>
            <p className="dark">
              <span
                style={{ display: "inline-block", margin: 0, color: "red" }}
              >
                *
              </span>
              Erforderlich
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactform;
