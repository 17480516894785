import React from "react";
import "./Textarea.scss";

const Textarea = ({ name, label, placeholder, onChange, value }) => {
  return (
    <div className="textarea">
      <label>{label}</label>
      <textarea
        name={name}
        placeholder={placeholder}
        rows={5}
        onChange={onChange}
        value={value}
      />
    </div>
  );
};

export default Textarea;
