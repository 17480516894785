import React from "react";
import Herosection from "../../../../components/herosection/Herosection";
import services from "../../../../assets/data/services.json";
import ServiceSection from "../../../../components/servicesection/Servicesection";

const ServicesOverview = () => {
  return (
    <ServiceSection/>
  );
};

export default ServicesOverview;
