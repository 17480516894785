import React, { useEffect } from "react";
import ContactformNew from "../../components/contactform/Contactform";
import "./contact.scss";

const ContactPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  return (
    <div className="container pt-80">
      <ContactformNew />
      <div className="contact_postal_address">
        <h2>Postanschrift</h2>
        <p>
          <span>J. Scheible & J. Oswald Albware GbR</span>
          <br />
          Schleienweg 5 <br />
          89584 Ehingen (Donau)
        </p>
      </div>
    </div>
  );
};

export default ContactPage;
