import React, { useEffect } from "react";
import "./about.scss";
import Herosection from "../../components/herosection/Herosection";
import persons from "./Data.json";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  });

  return(
    <>
    {persons.map((person, index) => {
    return <Herosection {...person} key={index} />;
    })}
    </>
  )
}

export default AboutPage
