import React from "react";
import "./Checkbox.scss";

const Checkbox = ({ label, onClick }) => {
  return (
    <form className="checkbox">
      <input id="confirm" type="checkbox" onClick={onClick} />
      <label dangerouslySetInnerHTML={{ __html: label }}></label>
    </form>
  );
};

export default Checkbox;
