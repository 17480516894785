import React, { useEffect } from "react";
import Herosection from "../../components/herosection/Herosection";
import services from "../../assets/data/services.json";

const ServicesPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  return (
    <>
      {services.map((service, index) => {
        return <Herosection {...service} key={index} />;
      })}
    </>
  );
};

export default ServicesPage;
