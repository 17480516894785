import React from "react";
import "./Input.scss";

const Input = ({
  name,
  label,
  autoComplete,
  placeholder,
  required,
  onChange,
  value,
  error,
}) => {
  return (
    <div className={`input${error ? " error" : ""}`}>
      <label>
        {label}
        {required && (
          <p style={{ display: "inline-block", margin: 0, color: "red" }}>*</p>
        )}
      </label>
      <input
        name={name}
        autoComplete={autoComplete}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default Input;
