import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa";
import { MdFingerprint } from "react-icons/md";
import "./Footer.scss";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Über uns</h2>
            <Link to="/about">Wer sind wir?</Link>
            <Link to="/#projects">Unsere Projekte</Link>
            <Link to="/contact">Kontakt</Link>
          </div>
        </div>
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>Rechtliches</h2>
            <Link to="/privacy">Datenschutz</Link>
            <Link to="/imprint">Impressum</Link>
            {/*<Link to="/accessibility">Barrierefreiheit</Link>*/}
          </div>
          <div className="footer-link-items">
            <h2>Social Media</h2>
            <a href="https://www.instagram.com/alb.ware/?hl=de" target="_blank">
              Instagram
            </a>
            <a href="https://www.linkedin.com/company/88165425" target="_blank">
              LinkedIn
            </a>
          </div>
        </div>
      </div>
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link to="/" className="social-logo">
              <img height={32} src="images/logo.svg" />
              lbware.
            </Link>
          </div>
          <small className="website-rights">
            © {new Date().getFullYear()} J. Scheible & J. Oswald Albware GbR
          </small>
          <div className="social-icons">
            <a
              className="social-icon-link"
              href="https://www.instagram.com/alb.ware/?hl=de"
              target="_blank"
              rel="noreferrer"
              aria-label="Instagram"
            >
              <FaInstagram />
            </a>
            <a
              className="social-icon-link"
              href="https://www.linkedin.com/company/88165425"
              target="_blank"
              rel="noreferrer"
              aria-label="LinkedIn"
            >
              <FaLinkedin />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
