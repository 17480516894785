import React from "react";
import data from "../../assets/data/accessibility.json";

const AccessibilityPage = () => {
  return (
    <div className="hero-section dark">
      <div id="accessibility-container" className="container">
        <h1>{data.title}</h1>
        {data.sections.map((section) => {
          return (
            <div key={section.title}>
              <h2 className="mb">{section.title}</h2>
              <div
                className="mb-2 justify"
                dangerouslySetInnerHTML={{ __html: section.content }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AccessibilityPage;
