import React, { useEffect } from "react";
import data from "../../assets/data/privacy.json";

const PrivacyPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  return (
    <div className="hero-section dark pt-80">
      <div id="privacy-container" className="container">
        <h1>{data.title}</h1>
        {data.sections.map((section) => {
          return (
            <div key={section.title}>
              <h2 className="mb-2">{section.title}</h2>
              {section.subsections.map((subsection) => {
                return (
                  <div key={subsection.title}>
                    <h3 className="mb-2">{subsection.title}</h3>
                    {subsection.subsections ? (
                      <>
                        {subsection.subsections.map((subsubsection) => {
                          return (
                            <div key={subsubsection.title}>
                              <h4 className="mb-2">{subsubsection.title}</h4>
                              <div
                                className="mb justify"
                                dangerouslySetInnerHTML={{
                                  __html: subsubsection.content,
                                }}
                              />
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div
                        className="mb justify"
                        dangerouslySetInnerHTML={{ __html: subsection.content }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PrivacyPage;
