import React, { useEffect } from "react";
import data from "../../assets/data/imprint.json";

const ImprintPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  return (
    <div className="hero-section dark pt-80">
      <div id="imprint-container" className="container">
        <h1>{data.title}</h1>
        {data.sections.map((section) => {
          return (
            <div key={section.title}>
              <h2 className="mb">{section.title}</h2>
              <div
                className="mb-2 justify"
                dangerouslySetInnerHTML={{ __html: section.content }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ImprintPage;
