import React, { useEffect } from "react";
import Landingpage from "./sections/landingpage/Landingpage";
import ServicesOverview from "./sections/serviceoverview/ServiceOverview";
import Philosophy from "./sections/philosophy/Philosophy";
import Projects from "./sections/projects/Projects";
import Contact from "./sections/contact/Contact";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  return (
    <>
      <Landingpage />
      <ServicesOverview />
      <Philosophy />
      <Projects />
      <Contact />
    </>
  );
};

export default HomePage;
