import React from "react";
import Herosection from "../../../../components/herosection/Herosection";
import data from "./Data.json";

const Philosophy = () => {
  return (
    <div id="philosophy">
      <Herosection {...data} />
    </div>
  );
};

export default Philosophy;
