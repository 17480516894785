const ValidatedInput = ({
  name,
  value,
  placeholder,
  label,
  required,
  error,
  onBlur,
  onChange,
}) => {
  return (
    <div className={`input${error ? " error" : ""}`}>
      <label htmlFor={name}>
        {label}
        {required && (
          <p style={{ display: "inline-block", margin: 0, color: "red" }}>*</p>
        )}
      </label>
      <input
        name={name}
        autoComplete={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};

export default ValidatedInput;
